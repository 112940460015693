<template>
  <mobile-screen
    :header="true"
    screen-class="gray-bg icon-app1 sync-event-list"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="event-list icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: 'r_sync' }">
            <button>
              <icon icon="#cx-hea1-arrow-left" />
            </button>
          </router-link>
        </template>
        <div v-if="resourceEventName" class="component-title">
          {{ resourceEventName }}
        </div>
      </top-header-menu-wrapper>
    </template>

    <ul
      class="clebex-item-section pill"
      v-if="resourceEvents && resourceEvents.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="(resourceEvent, i) in resourceEvents"
        :key="resourceEvent.id"
      >
        <div class="clebex-item-content-wrapper">
          <span class="label">
            <span class="text">
              <span class="highlight">{{
                resourceEvent.resourceName
                  ? resourceEvent.resourceName
                  : resourceEvent.subject
              }}</span>
            </span>
            <div v-if="resourceEvent.resourceName">
              <span class="text">{{ resourceEvent.subject }}</span>
            </div>
            <div>
              <span class="text">{{
                resourceEvent.organizer.emailAddress.name
              }}</span>
            </div>
            <div>
              <span class="text"
                >{{
                  formatDate(
                    resourceEvent.datetime_from,
                    `${globalDateFormat || "DD MMMM YYYY"}`
                  )
                }}
                |
                {{
                  displayDate(
                    resourceEvent.datetime_from,
                    globalTimeFormat || "HH:mm"
                  )
                }}
                -
                {{
                  displayDate(
                    resourceEvent.datetime_to,
                    globalTimeFormat || "HH:mm"
                  )
                }}</span
              >
            </div>
          </span>
          <span
            class="follow-up-icons"
            @click="syncResourceEvent(resourceEvent, i)"
          >
            <button class="follow-up-icon-item" :disabled="processing">
              <icon
                ref="refreshIcon"
                icon="#cx-foo1-refresh-28x28"
                :class="`event-list-refresh-icon ${syncIconStyle[i]}`"
              ></icon>
            </button>
          </span>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn active"
              :disabled="processing"
              @click="syncAll"
            >
              {{ displayLabelName("sync", "event-list", "sync-all") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapGetters, mapActions } from "vuex";
import { formatDate, displayDateWithTimezone } from "@/services/helpers";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
export default {
  name: "Sync",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      syncIconStyle: [],
      processing: false
    };
  },
  computed: {
    ...mapState("resource", [
      "resourceEvents",
      "resourceEventName",
      "resourceEventSyncMessages",
      "resourceEventSyncInit"
    ]),
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("timezone", ["timezones"])
  },
  created() {
    if (!this.resourceEvents) {
      this.$router.push({
        name: "r_sync"
      });
    } else {
      for (let index = 0; index < this.resourceEvents.length; index++) {
        this.syncIconStyle.push("");
      }
    }
  },
  methods: {
    ...mapActions("resource", [
      "getResourceCalendars",
      "setResourceEventSyncMessages"
    ]),
    formatDate(date, format) {
      return formatDate(date, format);
    },
    displayDate(date, format) {
      if (this.timezones && this.timezones.data) {
        return displayDateWithTimezone({
          date,
          format,
          timezones: this.timezones,
          noTimezone: true
        });
      }
      return "";
    },
    async syncResourceEvent(eventDetails, idx) {
      this.processing = true;
      this.syncIconStyle[idx] = "in-progress";
      const postData = { data: [eventDetails] };
      return httpServiceAuth
        .post(`${apiEndpoints.company.resourceEvents}/sync`, postData)
        .then(response => {
          const resourceEventSyncMessages = JSON.parse(
            JSON.stringify(this.resourceEventSyncMessages)
          );
          resourceEventSyncMessages[eventDetails.id] = response.data;
          this.$store.commit(
            "resource/setResourceEventSyncMessages",
            resourceEventSyncMessages,
            { root: true }
          );
          this.syncIconStyle[idx] = "done";
        })
        .catch(error => {
          this.syncIconStyle[idx] = "error";
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    async syncAll() {
      this.syncIconStyle = [];
      for (let index = 0; index < this.resourceEvents.length; index++) {
        this.syncIconStyle.push("");
      }
      for (const [idx, resourceEvent] of this.resourceEvents.entries()) {
        await this.syncResourceEvent(resourceEvent, idx);
      }
    }
  },
  watch: {
    resourceEventSyncInit(val) {
      if (val) {
        this.syncAll();
      }
    },
    resourceEvents: {
      handler() {
        if (this.resourceEvents && this.resourceEvents.length) {
          this.syncIconStyle = [];
          for (let index = 0; index < this.resourceEvents.length; index++) {
            this.syncIconStyle.push("");
          }
        }
      },
      deep: true
    }
  }
};
</script>
